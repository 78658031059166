<template>
  <div id="wrapper">
    <div class="page pb-3">
      <full-page-content>
        <div class="row">
          <div class="col-12 col-md-2 col-lg-2"></div>
          <div class="col-12 col-md-8 col-lg-8 my-auto">
            <div class="content text-center">
              <img class="h3-logo"
                    src="../assets/images/h3_logo_darker_shadow.png"
                    alt="logo"
                    @click="goBack" />
              <h2 class="text-content">
                Download the
                <br />
                Healthy Hip Hop App
              </h2>
                <!-- <p class="text-content">
                  Download the
                  <br />
                  Healthy Hip Hop App
                </p> -->
                <div class="text-center">
                  <img class="app-store download-icons"
                    :src='appStoreImg'
                    alt="app-store-badge"
                    @click="redirect('appStore')" />

                <img class="play-store download-icons"
                    :src='playStoreImg'
                    alt="play-store-badge"
                    @click="redirect('playStore')" />
                
                <br>
                <img class="mobile"
                    src="../assets/images/iphone_screen.png"
                    alt="mobile" />
                </div>
            </div>
          </div>
          <div class="col-12 col-md-2 col-lg-2"></div>
        </div>
      </full-page-content>
    </div>
  </div>
</template>

<script>
  const FullPageContent = () => import('@/components/shared/FullPageContent');
  
  export default {
    name : 'DownloadMobile',
    data() {
      return {
        appStoreImg : 'https://h3-bucket-s3.s3-us-west-2.amazonaws.com/' +
          'appstore_download.svg',
        playStoreImg : 'https://h3-bucket-s3.s3-us-west-2.amazonaws.com/' +
          'playstore_download.svg',
        appStoreLink : 'https://apps.apple.com/us/app/healthy-hip-hop/' +
          'id1456576616',
        playStoreLink : 'https://play.google.com/store/apps/details?' +
          'id=com.healthyhiphop',
        isLoggedIn : this.$store.getters['auth/isLoggedIn'],
      }
    },
    components : {
      FullPageContent,
    },
    methods : {

      /**
       * Redirect page to download H3 App
       * @param store
       */
      redirect(store) {
        if(store === 'appStore'){
          this.$analytics.fbq.event('mobile-download', {
            'page'     : 'mobile-landing-page',
            'platform' : 'website',
            'to'       : 'appstore-ios',
          });
          window.open(this.appStoreLink, '_target');
        } else {
          this.$analytics.fbq.event('mobile-download', {
            'page'     : 'mobile-landing-page',
            'platform' : 'website',
            'to'       : 'playstore-android',
          });
          window.open(this.playStoreLink, '_target');
        }

        // window.open((store === 'appStore') ? this.appStoreLink :
        //   this.playStoreLink, '_target');
      },

      /**
       * Go Back to Site
       */
      goBack() {
        this.$router.push('/');
      },
    },
    mounted () {
      this.$analytics.fbq.event('PageView', {
        'page'     : 'mobile-landing-page',
        'platform' : 'website',
      });
    },
  }
</script>

<style lang="scss" scoped>
  @import "../assets/scss/components/download-mobile";
</style>